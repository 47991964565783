import React from "react";
import ContactForm from '../Components/ContactForm';
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Carousel from '../Components/Carousel.tsx';
import ServicesData from "../Shared/Services.json"
import CarouselTile from "../Components/CarouselTile.js";
import { Link } from 'react-router-dom';
// import ImageCarousel from '../Components/ImageCarousel';

import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';


export const HomePage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        //ReactPixel.init('3426767080803273'); // Ersetzen Sie 'YOUR_PIXEL_ID' durch Ihre tatsächliche Pixel-ID
        ReactPixel.pageView(); // Für das Tracking von Seitenaufrufen
      }, []);


    return (
        <>
            <Helmet>
                <title>Home</title>
                <meta name="description" content="Steuerkanzlei Bailänder aus Landshut" />
            </Helmet>

            {/*Text und Diamonds*/}
            <Container>
                <Row className="justify-content-md-center" >
                    <Col md={12} >
                        <Image className='image-gruppe-top' style={{ marginTop: 80, width: '100%' }} src='../img/title-images/bailaender_gruppe_v2.jpg' fluid alt='home-title'></Image>
                    </Col>
                    <Col md={12} lg={8} >
                        <h1 style={{ marginBottom: 10 }} className='main-page-heading-subtitle' >{t('home.title')}</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 100, marginTop: 150 }}>
                    <Col md={12} lg={5}>
                        <h1>{t('home.subtitle')}</h1>
                    </Col>
                    <Col md={12} lg={{ span: 7 }}>
                        <p >{t('home.ourFirmText1')}</p>
                        <p >{t('home.ourFirmText2')}</p>
                    </Col>
                    
                </Row>
            </Container>


            {/*Carousel Component*/}
            <Container className="carousel-container-home">
                <Carousel>
                    {ServicesData.ServiceList.map(item => {
                        return (
                            <CarouselTile key={item.id} value={item.value} icon={item.icon} title={item.title} description={item.description} link={item.link} />
                        );
                    })}
                </Carousel>
            </Container>


            {/*Blog Component*/}
            <Container>
                <div style={{ height: 150 }}></div>
                <Row>
                    <Col md={12} lg={8}>
                        <Image style={{ width: '100%', marginBottom: 20 }} src='../img/home-blog.jpg' fluid alt='home-blog'></Image>
                    </Col>
                    <Col md={12} lg={4} style={{ margin: 'auto' }} >
                        <h2 style={{ marginBottom: 20 }} > {t('home.blogTitle')}</h2>
                        <p style={{ marginBottom: 20 }}> {t('home.blogDes')} </p>
                        <a href="/immobilien" as={Link} to="/immobilien" ><button style={{ marginLeft: 0 }} variant="dark" className="button-orange-whiteBG">{t('home.blogButton')}</button></a>
                    </Col>
                </Row>
                <div style={{ height: 150 }}></div>
            </Container>


            {/*Kontakt Feld*/}
            <div className='contact-form-wraper'>
                <Container>
                    <ContactForm></ContactForm>
                </Container>
            </div>


            {/*Berater Porträts*/}
            <div className='berater-wraper'>
                <Container>
                    <Row>
                        <Col className="berater-wraper-upper-col1" sm={12} md={5}>
                            <img className="berater-wraper-bottom-cols-image" src='./img/mitarbeiter/mitarbeiter_bailaender_sw.jpg' alt=''></img>
                        </Col>
                        <Col sm={0} md={1}>
                            {/* EMPTY COL */}
                        </Col>
                        <Col className="berater-wraper-upper-col2" sm={12} md={5}>
                            <h2><span style={{ color: '#E9521D' }}>Marcel</span> <span style={{ color: '#2D2A29' }}>Bailänder</span></h2>
                            <p>{t('general.taxConsultant')}<br />
                                Diplom-Finanzwirt (FH)</p>
                        </Col>
                    </Row>
                    <Row>
                        <a href='/team' as={Link}><button className='button-orange-whiteBG' type="button">{t('home.knowtheTeam')}</button></a>
                    </Row>


                </Container>

                {/* <div className="home-image-carousel" style={{ marginBottom: 50, marginTop:200 }}>

                    <ImageCarousel></ImageCarousel>
                </div> */}

            </div>
        </>
    )
}

export default HomePage;
